import * as React from "react"
import Layout from "../components/layout"
import { Container } from 'react-bootstrap'
import * as styles from './index.module.scss'

// markup
export default function IndexPage() {
  return (
    <Layout>
      <section className={styles.landing}>
        <Container>
          <h1>Investing in a more efficient planet</h1>
        </Container>
      </section>
      <section id="About" className={styles.about}>
        <Container>
          <h2>About Fjord</h2>
          We are a London-based private equity advisor focused on industrial resource efficiency, healthcare and space related businesses.
        </Container>
      </section>
      <section id="Contact" className={styles.contact}>
        <Container>
          <div className="card text-center">
            <div className="image"></div>
            <div className="content">
              <h2>Contact Us</h2>
              Fjord Advisors Limited
              <br/><br/>
              <a href="mailto:info@fjordcap.com">info@fjordcap.com</a>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
